import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  SaveDrivingExperience,
  SetEducation,
  SetPreferences,
  SetProfilePicture,
  SetDrivingExperience,
  SetDriver,
  UpdateDriver,
  ClearDriver,
  ClearRegistration,
  SetNotification,
  SetDispatcherContact,
  SetWorkPlaceSafety,
  SetLicense,
  UpdateLicense,
  ClearLicense
} from './registration.actions';
import { Employment } from 'src/app/shared/models/employment.model';
import { TruckType } from 'src/app/registration/shared/models/truck-type.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Education } from 'src/app/shared/models/education.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Notification } from 'src/app/shared/models/notification.model';
import { EmploymentHistory } from '../shared/models/employment-history.model';
import { Image } from 'src/app/registration/shared/models/image.model';
import { DispatcherContact } from 'src/app/shared/models/dispatcher-contact.model';
import { Directive } from '@angular/core';
import dayjs from 'dayjs';
import { companyAssociation } from 'src/app/shared/models/company-associations.model';
import { WorkPlaceSafety } from 'src/app/shared/models/workplacesafety.model';

export interface DriverStateModel {
  id: string;
  driverID: string;
  firstName: string;
  lastName: string;
  email: string;
  identityStatus: string;
  referralCode: string;
  phoneNumber: string;
  birthday: string | Date;
  gender: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  licenseType: string;
  driverType: string;
  issuingAuthority: string;
  notification: Notification[];
  createdDate: string | Date;
  modifiedDate: string | Date;
  stats: DriverStatsModel;
  documentIdentityConsent: boolean;
  companyAssociatedId: string;
  sin?: string;
  companyDriverType?: string;
  companyAssociations?: companyAssociation[];
  employmentRequirementsCompleted?: boolean;
  residenceHistoryRequirementsCompleted?: boolean;
  stage?: string;
  emergencyContacts?: any[];
}

export interface DriverStatsModel {
  shipmentsCompleted: number;
  shipmentUpcoming: number;
  shipmentResponseRate: number;
  shipmentEarnedThisMonth: number;
}

export interface RegistrationKYCVerificationDataStateModel {
  documentFrontImage: Image;
  documentBackImage: Image;
  livePhoto: Image;
  documentType: string;
}

export interface NotificationStateModel {
  notifications: Notification[];
}

export interface RegistrationEducationStateModel {
  id: string;
  highestGradeCompleted: string;
  lastSchoolAttended: string;
  certification: string;
  specialCourses: string;
}

export interface RegistrationWorkPlaceSafetyStateModel {
  id: string;
  incorporationNumber: string;
  craNumber: string;
  incorporationName: string;
  incorporationAddress: string;
  haveWorkplaceSafetyAccount: boolean;
  haveDisabilityBenefit: boolean;
  WsibClearance?: string;
  DisabilityBenefitProofInsurance?: string;
  DisabilityBenefitPolicyDocument?: string;
}

export interface RegistrationDrivingExperienceStateModel {
  id: string;
  noTruckingExperience: boolean;
  truckTypes?: TruckType[];
}

export interface RegistrationPreferencesStateModel {
  id: string;
  loadWeightPreference?: string;
  maxDeadheadMiles?: string;
  favouriteLaneOrigin?: string;
  favouriteLaneOriginCity?: string;
  favouriteLaneOriginState?: string;
  favouriteLaneDestination?: string;
  favouriteLaneDestinationCity?: string;
  favouriteLaneDestinationState?: string;
  earningType?: string;
  trailerType?: string;
  baseLocation?: string;
  baseLocationCity?: string;
  baseLocationState?: string;
  crossBorder?: string;
  usZone?: string;
  daysAwayFromBaseLocation?: string;
  truckType?: any;
}

export interface RegistrationPreferenceStateTruckTypeModel {
  any: boolean;
  hhg: boolean;
  reefer: boolean;
  hazmat: boolean;
  tanker: boolean;
  carHauling: boolean;
  flatBed: boolean;
  van: boolean;
  specialized: boolean;
  lcv: boolean;
}

export interface RegistrationPreferenceStateDayModel {
  weekdays: boolean;
  weekend: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface RegistrationProfilePictureStateModel {
  id: string;
  type: string;
  createdDate: Date;
  fileUrl: string;
  status: string;
}

export interface LicenseStateModel {
  frontScanImageExtension: string;
  backScanImageExtension: string;
  frontScanImageUrl: string;
  frontScanImageFile: string;
  backScanImageUrl: string;
  backScanImageFile: string;
  id?: string;
  licenseType?: string;
  expiryDate?: string;
  issueDate?: string;
  licenseNumber?: string;
  issuingCountry?: string;
  issuingProvince?: string;
  hasAirBreakEndorsment?: boolean;
  hasDeniedLicense?: boolean;
  deniedLicenseExplanation?: string;
  hasSuspendedLicense?: boolean;
  suspendedLicenseExplanation?: string;
  status?: string;
  generationDate?: string;
  frontScanImagePresent?: boolean;
  backScanImagePresent?: boolean;
  frontImageUploadedDate?: any;
  backImageUploadedDate?: any;
}
const licenseDefault = {
  frontScanImageExtension: undefined,
  backScanImageExtension: undefined,
  frontScanImageUrl: undefined,
  frontScanImageFile: undefined,
  backScanImageUrl: undefined,
  backScanImageFile: undefined,
  status: undefined,
  frontImageUploadedDate: undefined,
  backImageUploadedDate: undefined,
};
const driverStatsDefault = {
  shipmentsCompleted: undefined,
  shipmentUpcoming: undefined,
  shipmentResponseRate: undefined,
  shipmentEarnedThisMonth: undefined
} as DriverStatsModel;

const driverDefault = {
  id: undefined,
  driverID: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  identityStatus: undefined,
  referralCode: undefined,
  phoneNumber: undefined,
  birthday: undefined,
  gender: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  country: undefined,
  licenseType: undefined,
  driverType: undefined,
  issuingAuthority: undefined,
  notification: undefined,
  createdDate: undefined,
  modifiedDate: undefined,
  stats: driverStatsDefault,
  documentIdentityConsent: false,
  companyAssociatedId: undefined,
  companyDriverType: undefined,
  companyAssociations: undefined,
  employmentRequirementsCompleted: undefined,
  residenceHistoryRequirementsCompleted: undefined,
  stage: undefined,
  emergencyContacts: undefined
} as DriverStateModel;

const kycVerificationDataDefault = {
  documentFrontImage: undefined,
  documentBackImage: undefined,
  livePhoto: undefined,
  documentType: ''
} as RegistrationKYCVerificationDataStateModel;

const notificationsDefault = {
  notifications: []
} as NotificationStateModel;

const educationDefault = {
  id: undefined,
  highestGradeCompleted: undefined,
  lastSchoolAttended: undefined,
  certification: undefined,
  specialCourses: undefined
} as RegistrationEducationStateModel;

const workPlaceSafetyDefault = {
  id: undefined,
  incorporationNumber: undefined,
  craNumber: undefined,
  incorporationName: undefined,
  incorporationAddress: undefined,
  haveWorkplaceSafetyAccount: undefined,
  haveDisabilityBenefit: undefined,
  WsibClearance: undefined,
  DisabilityBenefitProofInsurance: undefined,
  DisabilityBenefitPolicyDocument: undefined
} as RegistrationWorkPlaceSafetyStateModel;

const drivingExperienceDefault = {
  id: undefined,
  noTruckingExperience: false,
  truckTypes: []
} as RegistrationDrivingExperienceStateModel;

const preferencesDefault = {
  id: '',
  loadWeightPreference: '',
  maxDeadheadMiles: '',
  favouriteLaneOrigin: '',
  favouriteLaneOriginCity: '',
  favouriteLaneOriginState: '',
  favouriteLaneDestination: '',
  favouriteLaneDestinationCity: '',
  favouriteLaneDestinationState: '',
  earningType: '',
  trailerType: '',
  baseLocation: '',
  baseLocationCity: '',
  baseLocationState: '',
  crossBorder: '',
  usZone: '',
  daysAwayFromBaseLocation: '',
  truckType: ''
} as RegistrationPreferencesStateModel;

const profilePictureDefault = {
  id: undefined,
  type: undefined,
  createdDate: undefined,
  fileUrl: undefined,
  status: undefined
};

const dispatcherContactDefault = {
  id: undefined,
  dispatcherContactId: undefined,
  dispatcherCompanyId: undefined,
  status: undefined,
  email: undefined,
  actorType: undefined,
  firstName: undefined,
  lastName: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
  country: undefined,
  companyName: undefined,
  phoneNumber: undefined
} as DispatcherContact;

export interface RegistrationStateModel {
  driver: DriverStateModel;
  notification: NotificationStateModel;
  kycVerificationData: RegistrationKYCVerificationDataStateModel;
  education: RegistrationEducationStateModel;
  drivingExperience: RegistrationDrivingExperienceStateModel;
  preferences: RegistrationPreferencesStateModel;
  profilePicture: RegistrationProfilePictureStateModel;
  dispatcherContact: DispatcherContact;
  workplaceSafety: RegistrationWorkPlaceSafetyStateModel;
  license: LicenseStateModel;
}

export interface ImageModel {
  imageAsDataUrl: string;
  file: any;
}

export interface DocumentFrontImageModel extends ImageModel {}
export interface DocumentBackImageModel extends ImageModel {}
export interface DocumentSelfieImageModel extends ImageModel {}

@State<RegistrationStateModel>({
  name: 'registration',
  defaults: {
    driver: driverDefault,
    notification: notificationsDefault,
    kycVerificationData: kycVerificationDataDefault,
    education: educationDefault,
    drivingExperience: drivingExperienceDefault,
    preferences: preferencesDefault,
    profilePicture: profilePictureDefault,
    dispatcherContact: dispatcherContactDefault,
    workplaceSafety: workPlaceSafetyDefault,
    license: licenseDefault
  }
})
@Directive({})
export class RegistrationState {
  constructor(private readonly driverApi: DriverApiService) {}

  @Selector() static driver(state: RegistrationStateModel) {
    return state.driver;
  }

  @Selector() static notification(state: RegistrationStateModel) {
    return state.notification;
  }

  @Selector() static kycVerificationData(state: RegistrationStateModel) {
    return state.kycVerificationData;
  }

  @Selector() static education(state: RegistrationStateModel) {
    return state.education;
  }

  @Selector() static workplaceSafety(state: RegistrationStateModel) {
    return state.workplaceSafety;
  }

  @Selector() static drivingExperience(state: RegistrationStateModel) {
    return state.drivingExperience;
  }

  @Selector() static preferences(state: RegistrationStateModel) {
    return state.preferences;
  }

  @Selector() static profilePicture(state: RegistrationStateModel) {
    return state.profilePicture;
  }

  @Selector() static dispatcherContact(state: RegistrationStateModel) {
    return state.dispatcherContact;
  }
  @Selector() static license(state: RegistrationStateModel) {
    return state.license;
  }

  @Action(ClearRegistration)
  clearRegistration(ctx: StateContext<RegistrationStateModel>, action: ClearRegistration) {
    ctx.setState({
      driver: driverDefault,
      notification: notificationsDefault,
      kycVerificationData: kycVerificationDataDefault,
      education: educationDefault,
      drivingExperience: drivingExperienceDefault,
      preferences: preferencesDefault,
      profilePicture: profilePictureDefault,
      dispatcherContact: dispatcherContactDefault,
      workplaceSafety: workPlaceSafetyDefault,
      license: licenseDefault
    });
  }

  @Action(SetDriver)
  setDriver(ctx: StateContext<RegistrationStateModel>, { payload }: SetDriver) {
    ctx.patchState({ driver: payload });
  }

  @Action(UpdateDriver)
  updateDriver(ctx: StateContext<RegistrationStateModel>, { payload }: UpdateDriver) {
    const driverDetails = Object.assign({}, payload, { notification: ctx.getState().driver.notification });
    ctx.patchState({ driver: driverDetails });
  }

  @Action(ClearDriver)
  clearDriver(ctx: StateContext<RegistrationStateModel>, action: ClearDriver) {
    ctx.patchState({ driver: undefined });
  }

  @Action(SetNotification)
  setNotification(ctx: StateContext<RegistrationStateModel>, { payload }: SetNotification) {
    if (payload) {
      ctx.patchState({ notification: { notifications: [...payload] } });
    }
  }

  @Action(SetEducation)
  setEducation(ctx: StateContext<RegistrationStateModel>, { payload }: SetEducation) {
    const education: Education = payload ? payload : educationDefault;
    ctx.patchState({ education });
  }

  @Action(SetWorkPlaceSafety)
  setWorkPlaceSafety(ctx: StateContext<RegistrationStateModel>, { payload }: SetWorkPlaceSafety) {
    const workplaceSafety: WorkPlaceSafety = payload ? payload : workPlaceSafetyDefault;
    ctx.patchState({ workplaceSafety });
  }

  @Action(SetDrivingExperience)
  setDrivingExperience(ctx: StateContext<RegistrationStateModel>, { payload }: SetDrivingExperience) {
    const drivingExperience: DrivingExperience = payload ? payload : drivingExperienceDefault;

    drivingExperience.truckTypes = drivingExperience.truckTypes || [];
    ctx.patchState({ drivingExperience });
  }

  @Action(SaveDrivingExperience)
  saveDrivingExperience(ctx: StateContext<RegistrationStateModel>, { payload }: SaveDrivingExperience) {
    return this.driverApi.createDrivingExperience(payload).subscribe((result: any) => {
      const drivingExperience: DrivingExperience = result.data;
      ctx.patchState({ drivingExperience });
    });
  }

  @Action(SetProfilePicture)
  setProfilePicture(ctx: StateContext<RegistrationStateModel>, { payload }: SetProfilePicture) {
    const profilePicture = payload ? (payload as RegistrationProfilePictureStateModel) : profilePictureDefault;
    ctx.patchState({
      profilePicture
    });
  }

  @Action(SetPreferences)
  setPreferences(ctx: StateContext<RegistrationStateModel>, { payload }: SetPreferences) {
    const preferences: Preferences = payload ? payload : preferencesDefault;
    ctx.patchState({ preferences });
  }

  @Action(SetDispatcherContact)
  setDispactherContact(ctx: StateContext<RegistrationStateModel>, { payload }: SetDispatcherContact) {
    const dispatcherContact: DispatcherContact = payload ? payload : dispatcherContactDefault;
    ctx.patchState({ dispatcherContact: dispatcherContact });
  }
  @Action(SetLicense)
  setLicense(ctx: StateContext<RegistrationStateModel>, { payload }: SetLicense) {
    ctx.patchState({ license: payload });
  }
  @Action(ClearDriver)
  clearLicense(ctx: StateContext<RegistrationStateModel>, action: ClearDriver) {
    ctx.patchState({ license: undefined });
  }
}
